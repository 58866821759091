<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div class="row" v-if="interview != null">
                <div class="col-12">
                    <div class="py-5 card bg-white border-0 shadow-xss rounded-lg overflow-hidden">
                        <div class="d-flex flex-row  border-0 w-100 p-0 mb-3 shadow-none">
                            <div class="mx-4 my-1 p-0 ml-1 btn-round-md rounded-xl bg-current">
                                <i class="fab fa-chromecast font-xl text-white"></i>
                            </div>
                            <div class="card-body p-0 pb-2 border-bottom border-dark">
                                <div class="row justify-content-between col-md-12">
                                    <h6 class="fw-600 text-grey-500 font-xssss">{{interview.zoom.start_time | formatDate}}</h6>
                                </div>
                                <div class="row justify-content-between col-md-12">
                                    <h2 class="font-lg text-grey-900 fw-600">{{interview.zoom.topic}}</h2>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-11 ml-5 pl-5">
                            <h4 class="text-grey-800 font-xs mt-4 fw-700">Agenda</h4>
                            <p class="font-xsss fw-600 lh-28 text-grey-600 mb-0 pl-0">{{interview.zoom.agenda}}</p>
                            <a :href="interview.zoom.start_url" class="btn btn-current btn-lg my-2">Start Interview</a>
                            <br><h4 class="text-grey-800 font-xs mt-4 fw-700">Participant</h4>
                            <div v-if="interview.participant.length" class="text-center">
                              <div class="table-responsive-xl">
                                  <datatable :class="'table table-hover table-bordered'" :columns="columns" :data="interview.participant" :page="1" :perPage="10">
                                      <template name="default" slot-scope="{ row, index }">
                                          <tr>
                                              <td>{{ index+1 }}</td>
                                              <td>{{ row.user.name }}</td>
                                              <td>{{ row.user.email }}</td>
                                          </tr>
                                      </template>
                                      <template name="no-result">
                                          Nothing to see here
                                      </template>
                                  </datatable>
                              </div>
                              <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 text-center">
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
        </div>
        <div class="col-12 text-center py-3" v-else>
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            interview: {},
            paramsId: this.$route.params.idCourse,
            idInterview: this.$route.params.idInterview,
            isLoad: true,
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
                {label: 'Name', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
                {label: 'Email', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
            ],
            page: 1
        }
    },
    created(){
        this.getInterview()
    },
    methods:{
        async getInterview(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/reflection/interview/detail?interview_id=${this.idInterview}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
              this.interview = res.data.data
              this.isLoad = false
            }).catch((err) => {
              this.isLoad = false
              console.log(err)
            })
        },
    }
}
</script>
